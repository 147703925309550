import { createReducer, on } from '@ngrx/store';
import { setLastPageStateUsers } from '@store/actions/users.actions';
import { SortOrder } from '@common/utils/search-sort';

export type UsersState = {
  lastPageState: LastPageStateUsers;
};

export type LastPageStateUsers = {
  searchValue: string;
  sortOrder: SortOrder;
};

export const initialStateUsers: UsersState = {
  lastPageState: {
    searchValue: '',
    sortOrder: SortOrder.Asc,
  },
};

export const usersReducer = createReducer(
  initialStateUsers,
  on(setLastPageStateUsers, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
  // on(setStateUsersSearchValue, (state, { searchValue }) => ({ ...state, searchValue })),
  // on(setStateUsersSortOrder, (state, { sortOrder }) => ({ ...state, sortOrder })),
);
