import { defaultColor } from '@common/utils/colors';
import { createReducer, on } from '@ngrx/store';
import {
  deleteTracksFromTrackPage,
  setLastPageStateTracks,
  setParamsCreateTrack,
} from '@store/actions/tracks.actions';
import {
  AvailableDateTimePeriod,
  AvailableRecentlyDateTimePeriodTypes,
  DateTimePeriodParams,
} from '@common/components/date-time-period/date-time-period.component';
import { CreateTrackParams, RoadEventTypes } from '@services/tracks.service';
import { AvailableMessagesParam } from '@store/reducers/messages.reducer';

export enum AvailableTabsOnTracksPage {
  Form = 'form',
  Tracks = 'tracks',
}

export enum AvailableTypesTrackColors {
  Single = 'single',
  Speed = 'speed',
  Trip = 'trip',
}

export type FormDataTracksPage = {
  selectedObject: any;
  selectedColorType: AvailableTypesTrackColors;
  selectedColorSingleTrack: string;
  trackLineWeight: number;
  isRequestMessagesParam: boolean;
  messagesParamsType: AvailableMessagesParam;
  detectorTrips: boolean;
  dateTimePeriod: DateTimePeriodParams;
  roadEvents: RoadEventsTypeWithStartStops;
};

export type RoadEventsTypeWithStartStops = {
  startEnds: boolean;
} & { [key in RoadEventTypes]: boolean };

export type LastTracksPageStateWithTabs = {
  activeTabOnPage: AvailableTabsOnTracksPage;
  formData: FormDataTracksPage;
  paramsCreateTrack: {
    [key: string]: CreateTrackParams;
  };
  tracksExpanded: { [key: string]: boolean };
};

export type TracksState = {
  lastPageState: LastTracksPageStateWithTabs;
};

export const initialStateTracks: TracksState = {
  lastPageState: {
    activeTabOnPage: AvailableTabsOnTracksPage.Form,
    formData: {
      selectedObject: null,
      selectedColorType: AvailableTypesTrackColors.Single,
      selectedColorSingleTrack: defaultColor,
      trackLineWeight: 5,
      isRequestMessagesParam: false,
      messagesParamsType: AvailableMessagesParam.Raw,
      detectorTrips: false,
      dateTimePeriod: {
        periodDateStart: null,
        periodDateEnd: null,
        periodValue: AvailableDateTimePeriod.Today,
        recentlyPeriod: 1,
        recentlyPeriodType: AvailableRecentlyDateTimePeriodTypes.Days,
        currentPeriod: false,
      },
      roadEvents: {
        [RoadEventTypes.parking]: false,
        [RoadEventTypes.stop]: false,
        [RoadEventTypes.refuel]: false,
        [RoadEventTypes.plum]: false,
        [RoadEventTypes.overSpeed]: false,
        startEnds: false,
      },
    },
    paramsCreateTrack: {},
    tracksExpanded: {},
  },
};

export const tracksReducer = createReducer(
  initialStateTracks,
  on(setLastPageStateTracks, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
  on(setParamsCreateTrack, (state, { params, cacheKey }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        paramsCreateTrack: { ...state.lastPageState.paramsCreateTrack, [cacheKey]: params },
      },
    };
  }),
  on(deleteTracksFromTrackPage, (state, { cacheKeys }) => {
    const newParamsCreateTrack = { ...state.lastPageState.paramsCreateTrack };
    for (const key of cacheKeys) {
      delete newParamsCreateTrack[key];
    }

    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        paramsCreateTrack: cacheKeys.length ? newParamsCreateTrack : {},
        // paramsCreateTrack: newParamsCreateTrack,
      },
    };
  }),
);
