import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, tap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { AvailableTypesTrackColors } from '@store/reducers/tracks.reducer';
import { setParamsCreateTrack } from '@store/actions/tracks.actions';
import { Router } from '@angular/router';
import { PortalTabsE } from '@common/utils/consts/monitoring-tabs-config';
import { AvailableMessagesParam } from '@store/reducers/messages.reducer';
import { setParamsCreateTrackForMessages } from '@store/actions/messages.actions';
import { setParamsCreateTrackForReports } from '@store/actions/reports.actions';

export enum RoadEventTypes {
  overSpeed = 'overSpeed',
  parking = 'parking',
  plum = 'plum',
  refuel = 'refuel',
  stop = 'stop',
}

export type ResponseCreateTrack = {
  track: any;
  trips: any;
  parkings: any;
  refuels: any;
  plums: any;
  stops: any;
  overSpeeds: any;
  sensors: any;
  additional: any;
};

export type RequestCreateTrack = {
  object_id: any;
  start_date: any;
  end_date: any;
  track_type: any;
  detect_trips: any;
  withStops: any;
  withParkings: any;
  withRefuels: any;
  withPlums: any;
  withOverSpeed: any;
  cacheKey?: string;
};

export type CreateTrackParams = {
  cacheKey?: string;
  cacheExpired?: string;
  objectId: number;
  startDate: string;
  endDate: string;
  trackType: AvailableTypesTrackColors;
  detectorTrips: boolean;
  isRequestMessagesParam?: boolean;
  messagesParamsType?: AvailableMessagesParam;
  trackLineWeight?: number;
  withStartEnds?: boolean;
  colorSingleTrack?: string;
} & Partial<{ [key in RoadEventTypes]: boolean }>;

@Injectable({
  providedIn: 'root',
})
export class TracksService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private router: Router,
  ) {}

  public objectIdForFastTrack$ = new BehaviorSubject<any>(null);
  public selectedTrack = new ReplaySubject(1);
  public deleteTrack = new Subject();
  public closeTrack = new Subject();
  public addTrack = new Subject();

  public addresses: any = {};

  textErrOnNotCoordinates = 'There is no data for the selected period';

  createTrack(params: CreateTrackParams): Observable<ResponseCreateTrack> {
    const body: RequestCreateTrack = {
      object_id: params.objectId,
      start_date: params.startDate,
      end_date: params.endDate,
      track_type: params.trackType,
      detect_trips: params.detectorTrips,
      withStops: params.stop,
      withParkings: params.parking,
      withRefuels: params.refuel,
      withPlums: params.plum,
      withOverSpeed: params.overSpeed,
    };

    if (params.cacheKey) {
      body['cacheKey'] = params.cacheKey;
    }

    return this.httpClient.post('tracks/create/', body).pipe(
      tap((track: any) => {
        const currentPage = this.router.url.split('/portal/')[1];

        if (
          track.track.messagesCoordinates.length === 0 &&
          !currentPage.startsWith(PortalTabsE.Reports)
        ) {
          throw new Error(this.textErrOnNotCoordinates);
        } else {
          if (currentPage.startsWith(PortalTabsE.Tracks)) {
            this.store.dispatch(
              setParamsCreateTrack({
                params: {
                  ...params,
                  cacheExpired: track.track.cacheExpired,
                },
                cacheKey: track.track.cacheKey,
              }),
            );
          } else if (currentPage.startsWith(PortalTabsE.Messages)) {
            this.store.dispatch(
              setParamsCreateTrackForMessages({
                params: {
                  ...params,
                  cacheExpired: track.track.cacheExpired,
                },
                cacheKey: track.track.cacheKey,
              }),
            );
          } else if (currentPage.startsWith(PortalTabsE.Reports)) {
            this.store.dispatch(
              setParamsCreateTrackForReports({
                params: {
                  ...params,
                  cacheExpired: track.track.cacheExpired,
                },
                cacheKey: track.track.cacheKey,
              }),
            );
          }
        }
      }),
      map((track: any) => {
        return {
          ...track,
          additional: {
            isRequestMessagesParam: params?.isRequestMessagesParam,
            messagesParamsType: params?.messagesParamsType,
            detectorTrips: params.detectorTrips,
            trackLineWeight: params?.trackLineWeight,
            trackType: params.trackType,
            colorSingleTrack: params?.colorSingleTrack,
            withStartEnds: params?.withStartEnds,
          },
        };
      }),

      catchError((error: any) => {
        return throwError(error);
      }),
    );
  }

  deleteTracksFromCache(cacheKeys: string[]) {
    return this.httpClient.post(`tracks/delete/`, { uuidKeys: cacheKeys });
  }

  getTrips(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/trips/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getStops(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/stops/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getParkings(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/parkings/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getRefuels(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/refuels/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getPlums(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/plums/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }
}
