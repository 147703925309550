import { createAction, props } from '@ngrx/store';
import { LastMessagesPageStateWithTabs } from '@store/reducers/messages.reducer';
import { CreateTrackParams } from '@services/tracks.service';

export const setLastPageStateMessages = createAction(
  '[stateMessages] Set last page state',
  props<{
    newPageState: Partial<LastMessagesPageStateWithTabs>;
  }>(),
);

export const setParamsCreateTrackForMessages = createAction(
  '[stateMessages] Set params create messages track',
  props<{
    params: CreateTrackParams;
    cacheKey: string;
  }>(),
);

export const deleteTracksFromMessagesPage = createAction(
  '[stateMessages] Delete track from messages page',
);
