export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export const sortedValues: SortOrder[] = [SortOrder.Asc, SortOrder.Desc];

export const getSortIndexValue = (sortOrder: SortOrder): number => {
  return sortedValues.findIndex((value) => value === sortOrder);
};

export const getSortIcon = (sortOrder: SortOrder) => {
  return sortOrder === SortOrder.Desc
    ? 'common/assets/icons/sort_Z-A.svg'
    : 'common/assets/icons/sort_A-Z.svg';
};

export const getSortedList = (list: unknown[], sortOrder: SortOrder) => {
  return sortOrder === SortOrder.Desc
    ? list.sort((a: any, b: any) => b.name.localeCompare(a.name))
    : list.sort((a: any, b: any) => a.name.localeCompare(b.name));
};

export const getSearchSortValue = (
  list: any,
  searchValue: string | null = '',
  sortOrder: SortOrder,
  sortedKeys: string[] = [],
) => {
  if (searchValue) {
    const filteredList = list.filter((o: any) => {
      return [...sortedKeys, 'name'].some((key) =>
        o[key]?.toString().toLowerCase().includes(searchValue.toString().toLowerCase()),
      );
    });

    return getSortedList(filteredList, sortOrder);
  } else {
    return getSortedList(list, sortOrder);
  }
};
