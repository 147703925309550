<app-night-theme *ngIf="night | async"></app-night-theme>

<app-push-blocked-account></app-push-blocked-account>

<app-progress-import-popup
  *ngIf="(importObjectsList$ | async)?.length || false"
></app-progress-import-popup>

<tui-loader [overlay]="appLoading" [showLoader]="appLoading">
  <div class="portal">
    <div class="div1">
      <app-header></app-header>
    </div>
    <div
      class="route-component control-block"
      [class.on-show-map-fullscreen]="portalService.isShowMapFullScreen | async"
    >
      <div tuiResizeable class="right-route-resizeable" [style.width]="widthPortal">
        <ng-container *ngIf="!appLoading">
          <router-outlet></router-outlet>
        </ng-container>

        <div
          class="right-route-resizeable-handle"
          [tuiResizer]="[1, 0]"
          (tuiSizeChange)="onChangeSizeContentRoute($event)"
        ></div>
      </div>
    </div>

    <div class="map div3" (mouseleave)="onMouseLeaveMap($event)" (click)="onMouseClickMap()">
      <div class="flexible-window" cdkDragBoundary=".portal" cdkDrag>
        <div class="header" cdkDragHandle></div>

        <div [style.display]="(portalService.isVisibleTrackPlayer | async) ? 'contents' : 'none'">
          <app-track-player *ngIf="tracksService.selectedTrack | async"></app-track-player>
        </div>
        <div
          *ngIf="portalService.isVisibleCompareComponent | async"
          [style.display]="
            isVisibleMessages && (messagesService.currentView | async) === 'graph'
              ? 'contents'
              : 'none'
          "
        >
          <app-compare></app-compare>
        </div>
      </div>
      <app-room-scheme-view *ngIf="portalService.isVisibleScheme | async"></app-room-scheme-view>
      <ng-container *tuiLet="!!(messagesData$ | async) as msgData">
        <div
          *ngIf="portalService.isVisibleMap | async"
          tuiResizeable
          class="bottom-map-resizeable"
          [style.height.px]="heightMessages"
          [class.max-height]="isVisibleReport || (msgData && isVisibleMessages)"
        >
          <app-map
            (objectInfoForPopup)="onUpdateObjectInfo($event)"
            (objectAddressForPopup)="onUpdateObjectAddress($event)"
          ></app-map>

          <div
            class="bottom-map-resizeable-handle"
            [class.display-none]="!(isVisibleReport || (isVisibleMessages && msgData))"
            [tuiResizer]="[0, 1]"
            (tuiSizeChange)="onChangeSizeMap($event)"
          ></div>
        </div>
        <div *ngIf="msgData" [style.display]="isVisibleMessages ? 'contents' : 'none'">
          <app-view-messages [topValue]="topValue"></app-view-messages>
        </div>
        <app-report-result [topValue]="topValue" *ngIf="isVisibleReport"></app-report-result>
      </ng-container>
    </div>
  </div>
</tui-loader>

<div class="popup-container" *ngIf="initObject">
  <app-object-tooltip
    [initObject]="initObject"
    [initAddress]="initAddress"
    (objectIntoPopup)="closePopup($event)"
  ></app-object-tooltip>
</div>

<!-- <div
  class="popup-cluster-container"
  *ngIf="objectsInClusterForPopup"
  [style.top.px]="clientY"
  [style.left.px]="clientX"
>
  <app-cluster-tooltip
    [objectsInCluster]="objectsInClusterForPopup"
    (objectsIntoPopup)="closeClusterPopup($event)"
    (objectForPopup)="onUpdateObjectInfo($event)"
    (objectAddressForPopup)="onUpdateObjectAddress($event)"
  ></app-cluster-tooltip>
</div> -->
