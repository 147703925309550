import {createReducer, on} from '@ngrx/store';
import {setLastPageStateBuildings, storeBuildings} from '../actions/buildings.actions';
import {SortOrder} from '@common/utils/search-sort'; //

//
// TODO: introduce typing into components
//

export type BuildingItem = {
  id: number;
  lat: number;
  lng: number;
  name: string;
  address: string;
  rooms: RoomItem[];
};

export type RoomItem = {
  building: number;
  id: number;
  name: string;
  schema: string;
  devices: RoomItemDevice[];
  devicesPositions: RoomItemDevicePosition[];
};

export type RoomItemDevice = {
  deviceModelId: number;
  id: number;
  // TODO: add type
  lastData: any;
  name: string;
  uniqueId: string;
};

export type RoomItemDevicePosition = {
  deviceId: number | null;
  x: number;
  y: number;
};

export type BuildingsState = {
  list: BuildingItem[];
  lastPageState: LastPageStateBuildings;
};

export type LastPageStateBuildings = {
  searchValue: string;
  sortOrder: SortOrder;
  openedBuildingId: number | null;
  openedRoomId: number | null;
};

export const initialStateBuildings: BuildingsState = {
  list: [],
  lastPageState: {
    searchValue: '',
    sortOrder: SortOrder.Asc,
    openedBuildingId: null,
    openedRoomId: null,
  },
};

export const buildingsReducer = createReducer(
  initialStateBuildings,
  on(storeBuildings, (state, { list }) => ({ ...state, list })),
  on(setLastPageStateBuildings, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
);
