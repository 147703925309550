import { createAction, props } from '@ngrx/store';
import { LastNotificationsPageStateWithTabs } from '@store/reducers/notifications.reducer';

export const getNotifications = createAction('[Notifications] Get');
export const storeNotifications = createAction('[Notifications] Store', props<{ list: any }>());
export const addNotification = createAction(
  '[Notifications] Add new notifications',
  props<{ newNotification: any }>(),
);
export const updateNotificationById = createAction(
  '[Notifications] Update notification by id',
  props<{ updatedNotification: any }>(),
);
export const deleteNotificationById = createAction(
  '[Notifications] Delete notifications by id',
  props<{ deletedNotificationId: any }>(),
);
export const updateIsActiveNotifications = createAction(
  '[Notifications] Update isActive',
  props<any>(),
);

export const setLastPageStateNotifications = createAction(
  '[stateNotifications] Set last page state',
  props<{ newPageState: Partial<LastNotificationsPageStateWithTabs> }>(),
);
