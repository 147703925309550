import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SortOrder } from '@common/utils/search-sort';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isLoading = new BehaviorSubject(false);

  objSort: SortOrder = SortOrder.Asc;
  objSearch: string | null = '';
  objGrSort: SortOrder = SortOrder.Asc;
  objGrSearch: string | null = '';
  isShowList = true;
  groupsExpanded: number | null = null;
  freeObjectsListExpanded = false;

  constructor(private httpClient: HttpClient) {}
}
