import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { deleteNotificationById } from '@store/actions/notifications.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  list() {
    return this.httpClient.get('notifications/');
  }

  getNotificationById(id: number) {
    return this.httpClient.get(`notifications/${id}/`);
  }

  listEvents(page: number, perPage: number, search?: string | null) {
    let params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());

    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get('notification_events/', { params });
  }

  createNotification(body: any) {
    return this.httpClient.post('notifications/', body);
  }

  editNotification(body: any, id: any) {
    return this.httpClient.put(`notifications/${id}/`, body);
  }

  updateIsActiveNotification(notificationId: number, state: boolean) {
    return this.httpClient.post(`notifications/${notificationId}/activate/`, { state });
  }

  deleteNotice(id: number) {
    return this.httpClient.delete(`notifications/${id}`).pipe(
      tap(() => {
        this.store.dispatch(deleteNotificationById({ deletedNotificationId: id }));
      }),
    );
  }
}
