import { messagesReducer, MessagesState } from '@store/reducers/messages.reducer';
import { tracksReducer, TracksState } from '@store/reducers/tracks.reducer';
import { devicesReducer, DevicesState } from '@store/reducers/devices.reducer';
import { usersReducer, UsersState } from '@store/reducers/users.reducer';
import { buildingsReducer, BuildingsState } from '@store/reducers/buildings.reducer';
import { notificationsReducer, NotificationsState } from '@store/reducers/notifications.reducer';
import { geozonesReducer, GeozonesState } from '@store/reducers/geozones.reducer';
import { objectsReducer, ObjectsState } from '@store/reducers/objects.reducer';
import { deviceModelsReducer } from '@store/reducers/device_models.reducer';
import { currentUserReducer } from '@store/reducers/current_user.reducer';
import { driversReducer, DriversState } from '@store/reducers/drivers.reducer';
import { importObjectsReducer } from '@store/reducers/import_objects.reducer';
import { reportsReducer, ReportsState } from '@store/reducers/reports.reducer';
import { ActionReducer } from '@ngrx/store';

export type StateType = {
  objects: ActionReducer<ObjectsState>;
  geozones: ActionReducer<GeozonesState>;
  deviceModels: ActionReducer<any>;
  currentUser: ActionReducer<any>;
  notifications: ActionReducer<NotificationsState>;
  drivers: ActionReducer<DriversState>;
  buildings: ActionReducer<BuildingsState>;
  importObjects: ActionReducer<any>;
  users: ActionReducer<UsersState>;
  devices: ActionReducer<DevicesState>;
  tracks: ActionReducer<TracksState>;
  messages: ActionReducer<MessagesState>;
  reports: ActionReducer<ReportsState>;
};

export const mainReducers: StateType = {
  objects: objectsReducer,
  geozones: geozonesReducer,
  deviceModels: deviceModelsReducer,
  currentUser: currentUserReducer,
  notifications: notificationsReducer,
  drivers: driversReducer,
  buildings: buildingsReducer,
  importObjects: importObjectsReducer,
  users: usersReducer,
  devices: devicesReducer,
  messages: messagesReducer,
  reports: reportsReducer,
  tracks: tracksReducer,
};
