import { createAction, props } from '@ngrx/store';
import { LastPageStateBuildings } from '@store/reducers/buildings.reducer';

export const getBuildings = createAction('[Buildings] Get');
export const storeBuildings = createAction('[Buildings] Store', props<{ list: any }>());

export const setLastPageStateBuildings = createAction(
  '[stateBuildings] Set last page state',
  props<{ newPageState: Partial<LastPageStateBuildings> }>(),
);
