import { createReducer, on } from '@ngrx/store';
import { setLastPageStateDrivers, storeDrivers } from '../actions/drivers.actions';
import { AvailableTabsOnPage, LastPageStateWithTabs } from '@store/types/basic-last-page-state';
import { SortOrder } from '@common/utils/search-sort';

export type DriversState = {
  list: any[];
  lastPageState: LastPageStateWithTabs;
};

export const initialStateDrivers: DriversState = {
  list: [],
  lastPageState: {
    activeTabOnPage: AvailableTabsOnPage.List,
    [AvailableTabsOnPage.List]: {
      searchValue: '',
      sortOrder: SortOrder.Asc,
    },
    [AvailableTabsOnPage.Groups]: {
      searchValue: '',
      sortOrder: SortOrder.Asc,
      openedGroupId: null,
      isOpenedFreeList: false,
    },
  },
};

export const driversReducer = createReducer(
  initialStateDrivers,
  on(storeDrivers, (state, { list }) => ({ ...state, list })),
  on(setLastPageStateDrivers, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
);
