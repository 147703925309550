import { createAction, props } from '@ngrx/store';
import { CreateTrackParams } from '@services/tracks.service';
import { LastReportsPageStateWithTabs } from '@store/reducers/reports.reducer';

export const setLastPageStateReports = createAction(
  '[stateReports] Set last page state',
  props<{
    newPageState: Partial<LastReportsPageStateWithTabs>;
  }>(),
);

export const setParamsCreateTrackForReports = createAction(
  '[stateReports] Set params create reports track',
  props<{
    params: CreateTrackParams;
    cacheKey: string;
  }>(),
);

export const deleteTracksFromReportsPage = createAction(
  '[stateReports] Delete track from reports page',
);
