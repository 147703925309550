import { CreateTrackParams } from '@services/tracks.service';
import { createReducer, on } from '@ngrx/store';
import {
  deleteTracksFromReportsPage,
  setLastPageStateReports,
  setParamsCreateTrackForReports,
} from '@store/actions/reports.actions';
import { SortOrder } from '@common/utils/search-sort';

export enum AvailableTabsOnReportsPage {
  Form = 'form',
  Result = 'result',
  Templates = 'templates',
}

export type LastReportsPageStateWithTabs = {
  activeTabOnPage: AvailableTabsOnReportsPage;
  searchValue: string;
  sortOrder: SortOrder;
  paramsCreateTrack: {
    [key: string]: CreateTrackParams;
  };
};

export type ReportsState = {
  lastPageState: LastReportsPageStateWithTabs;
};

export const initialStateReports: ReportsState = {
  lastPageState: {
    activeTabOnPage: AvailableTabsOnReportsPage.Form,
    searchValue: '',
    sortOrder: SortOrder.Asc,
    paramsCreateTrack: {},
  },
};

export const reportsReducer = createReducer(
  initialStateReports,
  on(setLastPageStateReports, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
  on(setParamsCreateTrackForReports, (state, { params, cacheKey }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        paramsCreateTrack: { [cacheKey]: params },
      },
    };
  }),
  on(deleteTracksFromReportsPage, (state) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        paramsCreateTrack: {},
      },
    };
  }),
);
