import { SortOrder } from '@common/utils/search-sort';

export enum AvailableTabsOnPage {
  List = 'list',
  Groups = 'groups',
}

export type ListTabPageState = {
  searchValue: string;
  sortOrder: SortOrder;
};

export type GroupsTabPageState = ListTabPageState & {
  openedGroupId: number | null;
  isOpenedFreeList: boolean;
};

export type LastPageStateWithTabs = {
  activeTabOnPage: AvailableTabsOnPage;
  [AvailableTabsOnPage.List]: ListTabPageState;
  [AvailableTabsOnPage.Groups]: GroupsTabPageState;
};
