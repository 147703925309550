import {
  TuiActiveZoneModule,
  TuiAlertHostModule,
  TuiAutofilledModule,
  TuiAutoFocusModule,
  TuiCheckedModule,
  TuiCopyProcessorModule,
  TuiDialogHostModule,
  TuiDragModule,
  TuiDropdownHostModule,
  TuiDroppableModule,
  TuiElementModule,
  TuiFilterPipeModule,
  TuiFocusableModule,
  TuiFocusedModule,
  TuiFocusTrapModule,
  TuiFocusVisibleModule,
  TuiForAsyncModule,
  TuiForModule,
  TuiHighDpiModule,
  TuiHoveredModule,
  TuiIsPresentPipeModule,
  TuiItemModule,
  TuiKeysPipeModule,
  TuiLetModule,
  TuiMapperPipeModule,
  TuiMediaModule,
  TuiObscuredModule,
  TuiOverscrollModule,
  TuiPanModule,
  TuiPortalModule,
  TuiPressedModule,
  TuiPreventDefaultModule,
  TuiRepeatTimesModule,
  TuiResizeModule,
  TuiResizerModule,
  TuiSwipeModule,
  TuiValidatorModule,
  TuiZoomModule,
} from '@taiga-ui/cdk';
import {
  TuiAlertModule,
  TuiButtonModule,
  TuiCalendarModule,
  TuiCalendarSheetPipeModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiExpandModule,
  TuiFlagPipeModule,
  TuiFormatDatePipeModule,
  TuiFormatNumberPipeModule,
  TuiFormatPhonePipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHintsHostModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiMaskAccessorModule,
  TuiModeModule,
  TuiMonthPipeModule,
  TuiNotificationModule,
  TuiPrimitiveCalendarModule,
  TuiPrimitiveCheckboxModule,
  TuiPrimitiveSpinButtonModule,
  TuiPrimitiveTextfieldModule,
  TuiPrimitiveYearMonthPaginationModule,
  TuiPrimitiveYearPickerModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiScrollControlsModule,
  TuiScrollIntoViewModule,
  TuiSvgDefsHostModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiThemeNightModule,
  TuiTooltipModule,
  TuiWrapperModule,
} from '@taiga-ui/core';
import {
  TextMaskModule,
  TuiAccordionModule,
  TuiActionModule,
  TuiArrowModule,
  TuiAvatarModule,
  TuiBadgedContentModule,
  TuiBadgeModule,
  TuiBreadcrumbsModule,
  TuiCalendarMonthModule,
  TuiCalendarRangeModule,
  TuiCarouselModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiComboBoxModule,
  TuiDataListDropdownManagerModule,
  TuiDataListWrapperModule,
  TuiElasticContainerModule,
  TuiFieldErrorPipeModule,
  TuiFilesModule,
  TuiFilterByInputPipeModule,
  TuiFilterModule,
  TuiHighlightModule,
  TuiInputCopyModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputDateRangeModule,
  TuiInputDateTimeModule,
  TuiInputFilesModule,
  TuiInputInlineModule,
  TuiInputModule,
  TuiInputMonthModule,
  TuiInputMonthRangeModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputPhoneInternationalModule,
  TuiInputPhoneModule,
  TuiInputRangeModule,
  TuiInputSliderModule,
  TuiInputTagModule,
  TuiInputTimeModule,
  TuiInputYearModule,
  TuiIslandModule,
  TuiItemsWithMoreModule,
  TuiLazyLoadingModule,
  TuiLineClampModule,
  TuiMarkerIconModule,
  TuiMultiSelectModule,
  TuiMultiSelectOptionModule,
  TuiPaginationModule,
  TuiPdfViewerModule,
  TuiPresentModule,
  TuiPrimitiveCalendarRangeModule,
  TuiProgressModule,
  TuiProjectClassModule,
  TuiPromptModule,
  TuiPushModule,
  TuiRadioBlockModule,
  TuiRadioGroupModule,
  TuiRadioLabeledModule,
  TuiRadioListModule,
  TuiRadioModule,
  TuiRangeModule,
  TuiRatingModule,
  TuiRoutableDialogModule,
  TuiSelectModule,
  TuiSelectOptionModule,
  TuiSliderModule,
  TuiSortCountriesPipeModule,
  TuiStepperModule,
  TuiStringifyContentPipeModule,
  TuiStringifyPipeModule,
  TuiTabsModule,
  TuiTagModule,
  TuiTextareaModule,
  TuiTilesModule,
  TuiToggleModule,
  TuiToYearPipeModule,
  TuiTreeModule,
  TuiUnfinishedValidatorModule,
  TuiValueAccessorModule,
} from '@taiga-ui/kit';
import {
  TuiAlignContentModule,
  TuiCodeModule,
  TuiColorEditModule,
  TuiColorPickerModule,
  TuiColorSelectorModule,
  TuiDetailsModule,
  TuiEditLinkModule,
  TuiEditorImagePreviewModule,
  TuiEditorModule,
  TuiEditorResizableModule,
  TuiEditorSocketModule,
  TuiFlatPickerModule,
  TuiFontSizeModule,
  TuiFontStyleModule,
  TuiHighlightColorModule,
  TuiIframeEditorModule,
  TuiImageEditorModule,
  TuiInputColorModule,
  TuiLinearMultiPickerModule,
  TuiLinearPickerModule,
  TuiListConfigsModule,
  TuiPaletteModule,
  TuiTableCellColorModule,
  TuiTableCreateModule,
  TuiTableMergeCellsModule,
  TuiTableRowColumnManagerModule,
  TuiTableSizeSelectorModule,
  TuiTextColorModule,
  TuiTiptapEditorModule,
  TuiToolbarModule,
} from '@taiga-ui/addon-editor';
import {
  TuiElasticStickyModule,
  TuiMobileCalendarDialogModule,
  TuiMobileCalendarModule,
  TuiMobileDialogModule,
  TuiMobileTabsModule,
  TuiPullToRefreshModule,
  TuiRippleModule,
  TuiSheetModule,
  TuiSidebarModule,
  TuiThemeAndroidModule,
  TuiThemeIosModule,
  TuiTouchableModule,
} from '@taiga-ui/addon-mobile';
import {
  TuiPreviewActionModule,
  TuiPreviewDialogModule,
  TuiPreviewModule,
} from '@taiga-ui/addon-preview';
import {
  TuiReorderModule,
  TuiTableFiltersModule,
  TuiTableModule,
  TuiTablePaginationModule,
} from '@taiga-ui/addon-table';
import { TuiTableBarsHostModule } from '@taiga-ui/addon-tablebars';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { NgModule } from '@angular/core';

const ALL_TAIGA_UI_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  ScrollingModule,
  PolymorpheusModule,
  /* CDK */
  TuiActiveZoneModule,
  TuiAlertHostModule,
  TuiAutoFocusModule,
  TuiAutofilledModule,
  TuiCheckedModule,
  TuiCopyProcessorModule,
  TuiDialogHostModule,
  TuiDragModule,
  TuiDropdownHostModule,
  TuiDroppableModule,
  TuiElementModule,
  TuiFilterPipeModule,
  TuiFocusTrapModule,
  TuiFocusVisibleModule,
  TuiFocusableModule,
  TuiFocusedModule,
  TuiForAsyncModule,
  TuiForModule,
  TuiHighDpiModule,
  TuiHoveredModule,
  TuiIsPresentPipeModule,
  TuiItemModule,
  TuiKeysPipeModule,
  TuiLetModule,
  TuiMapperPipeModule,
  TuiMediaModule,
  TuiObscuredModule,
  TuiOverscrollModule,
  TuiPanModule,
  TuiPortalModule,
  TuiPressedModule,
  TuiPreventDefaultModule,
  TuiRepeatTimesModule,
  TuiResizeModule,
  TuiResizerModule,
  TuiSwipeModule,
  TuiValidatorModule,
  TuiZoomModule,
  /* CORE */
  TuiAlertModule,
  TuiButtonModule,
  TuiCalendarModule,
  TuiCalendarSheetPipeModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiExpandModule,
  TuiFlagPipeModule,
  TuiFormatDatePipeModule,
  TuiFormatNumberPipeModule,
  TuiFormatPhonePipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHintsHostModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiMaskAccessorModule,
  TuiModeModule,
  TuiMonthPipeModule,
  TuiNotificationModule,
  TuiPrimitiveCalendarModule,
  TuiPrimitiveCheckboxModule,
  TuiPrimitiveSpinButtonModule,
  TuiPrimitiveTextfieldModule,
  TuiPrimitiveYearMonthPaginationModule,
  TuiPrimitiveYearPickerModule,
  TuiRootModule,
  TuiScrollControlsModule,
  TuiScrollIntoViewModule,
  TuiScrollbarModule,
  TuiSvgDefsHostModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiThemeNightModule,
  TuiTooltipModule,
  TuiWrapperModule,
  /* KIT */
  TextMaskModule,
  TuiAccordionModule,
  TuiActionModule,
  TuiArrowModule,
  TuiAvatarModule,
  TuiBadgeModule,
  TuiBadgedContentModule,
  TuiBreadcrumbsModule,
  TuiCalendarMonthModule,
  TuiCalendarRangeModule,
  TuiCarouselModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiComboBoxModule,
  TuiDataListDropdownManagerModule,
  TuiDataListWrapperModule,
  TuiElasticContainerModule,
  TuiFieldErrorPipeModule,
  TuiFilesModule,
  TuiFilterByInputPipeModule,
  TuiFilterModule,
  TuiHighlightModule,
  TuiInputCopyModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputDateRangeModule,
  TuiInputDateTimeModule,
  TuiInputFilesModule,
  TuiInputInlineModule,
  TuiInputModule,
  TuiInputMonthModule,
  TuiInputMonthRangeModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputPhoneInternationalModule,
  TuiInputPhoneModule,
  TuiInputRangeModule,
  TuiInputSliderModule,
  TuiInputTagModule,
  TuiInputTimeModule,
  TuiInputYearModule,
  TuiIslandModule,
  TuiItemsWithMoreModule,
  TuiLazyLoadingModule,
  TuiLineClampModule,
  TuiMarkerIconModule,
  TuiMultiSelectModule,
  TuiMultiSelectOptionModule,
  TuiPaginationModule,
  TuiPdfViewerModule,
  TuiPresentModule,
  TuiPrimitiveCalendarRangeModule,
  TuiProgressModule,
  TuiProjectClassModule,
  TuiPromptModule,
  TuiPushModule,
  TuiRadioBlockModule,
  TuiRadioGroupModule,
  TuiRadioLabeledModule,
  TuiRadioListModule,
  TuiRadioModule,
  TuiRangeModule,
  TuiRatingModule,
  TuiRoutableDialogModule,
  TuiSelectModule,
  TuiSelectOptionModule,
  TuiSliderModule,
  TuiSortCountriesPipeModule,
  TuiStepperModule,
  TuiStringifyContentPipeModule,
  TuiStringifyPipeModule,
  TuiTabsModule,
  TuiTagModule,
  TuiTextareaModule,
  TuiTilesModule,
  TuiToYearPipeModule,
  TuiToggleModule,
  TuiTreeModule,
  TuiUnfinishedValidatorModule,
  TuiValueAccessorModule,
  /* ADDON-CHARTS */
  // TuiArcChartModule,
  // TuiAxesModule,
  // TuiBarChartModule,
  // TuiBarModule,
  // TuiBarSetModule,
  // TuiLegendItemModule,
  // TuiLineChartModule,
  // TuiLineDaysChartModule,
  // TuiPieChartModule,
  // TuiRingChartModule,
  /* ADDON-COMMERCE */
  // TuiCardModule,
  // TuiCurrencyPipeModule,
  // TuiFormatCardModule,
  // TuiInputCVCModule,
  // TuiInputCardGroupedModule,
  // TuiInputCardModule,
  // TuiInputExpireModule,
  // TuiMoneyModule,
  /* ADDON-EDITOR */
  TuiAlignContentModule,
  TuiCodeModule,
  TuiColorEditModule,
  TuiColorPickerModule,
  TuiColorSelectorModule,
  TuiDetailsModule,
  TuiEditLinkModule,
  TuiEditorImagePreviewModule,
  TuiEditorModule,
  TuiEditorResizableModule,
  TuiEditorSocketModule,
  TuiFlatPickerModule,
  TuiFontSizeModule,
  TuiFontStyleModule,
  TuiHighlightColorModule,
  TuiIframeEditorModule,
  TuiImageEditorModule,
  TuiInputColorModule,
  TuiLinearMultiPickerModule,
  TuiLinearPickerModule,
  TuiListConfigsModule,
  TuiPaletteModule,
  TuiTableCellColorModule,
  TuiTableCreateModule,
  TuiTableMergeCellsModule,
  TuiTableRowColumnManagerModule,
  TuiTableSizeSelectorModule,
  TuiTextColorModule,
  TuiTiptapEditorModule,
  TuiToolbarModule,
  /* ADDON-MOBILE */
  TuiElasticStickyModule,
  TuiMobileCalendarDialogModule,
  TuiMobileCalendarModule,
  TuiMobileDialogModule,
  TuiMobileTabsModule,
  TuiPullToRefreshModule,
  TuiRippleModule,
  TuiSheetModule,
  TuiSidebarModule,
  TuiThemeAndroidModule,
  TuiThemeIosModule,
  TuiTouchableModule,
  /* ADDON-PREVIEW */
  TuiPreviewActionModule,
  TuiPreviewDialogModule,
  TuiPreviewModule,
  /* ADDON-TABLE */
  TuiReorderModule,
  TuiTableFiltersModule,
  TuiTableModule,
  TuiTablePaginationModule,
  /* ADDON-TABLEBARS */
  TuiTableBarsHostModule,
  /* EXAMPLE MODULES */
];

@NgModule({
  imports: [ALL_TAIGA_UI_MODULES],
  exports: [ALL_TAIGA_UI_MODULES],
})
export class TaigaModule {}
