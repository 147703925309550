import { getSearchSortValue } from '@common/utils/search-sort';

export const selectObjects = (state: any) => state.objects.list;

export const selectLastStateObjectsPage = (state: any) => state.objects.lastPageState;
export const selectLastStateOnTabObjectList = (state: any) => state.objects.lastPageState.list;
export const selectLastStateOnTabObjectGroups = (state: any) => state.objects.lastPageState.groups;

export const selectObjectsLastMessage = (state: any) =>
  state.objects.list.map((o: any) => ({ lastMessage: o.lastMessage }));

export const selectObjectsOnlyId = (state: any) => state.objects.list.map((o: any) => o.id);

export const selectObjectById = (state: any, { objectId }: { objectId: number | string }) =>
  state.objects.list.find((o: any) => String(o.id) === String(objectId));

export const selectObjectsByIds = (state: any, objectsIds: number[]) => {
  return state.objects.list.filter((f: any) => objectsIds.includes(f.id));
};

export const selectObjectsNotGroupByIds = (state: any, objectsIds: number[]) => {
  return state.objects.list.filter((f: any) => !objectsIds.includes(f.id));
};

export const selectAvailableObjectsByCurrentUserAccessKey = (state: any, key: string) => {
  return state.objects.list.filter((f: any) => f.currentUserAccess.includes(key));
};

export const selectSearchedSortedObjects = (
  state: any,
  {
    searchValue,
    sortedValue,
    objectsIds,
    useThisObjectsIds = true,
    freeObjectsGroup,
    visibleObjectsIds,
  }: any = {
    searchValue: null,
    sortedValue: null,
    objectsIds: null,
    useThisObjectsIds: true,
    freeObjectsGroup: false,
    visibleObjectsIds: null,
  },
) => {
  let list = objectsIds
    ? state.objects.list.filter((f: any) =>
        useThisObjectsIds ? objectsIds.includes(f.id) : !objectsIds.includes(f.id),
      )
    : [...state.objects.list];

  if (freeObjectsGroup) {
    list = list.filter((f: any) => visibleObjectsIds.includes(f.id));
  }

  return getSearchSortValue(list, searchValue, sortedValue, ['name', 'uniqueId']);
};
