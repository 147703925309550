import { createReducer, on } from '@ngrx/store';
import {
  addObject,
  deleteObjectById,
  setLastPageStateObjects,
  setLoaded,
  storeObjects,
  updateConnectedStatusForObject,
  updateLastMessageForObjects,
  updateObjectById,
} from '@store/actions/objects.actions';
import { SortOrder } from '@common/utils/search-sort';
import { AvailableTabsOnPage, LastPageStateWithTabs } from '@store/types/basic-last-page-state';

export type ObjectsState = {
  list: any[];
  loaded: boolean;
  lastPageState: LastPageStateWithTabs;
};

export const initialStateObjects: ObjectsState = {
  list: [],
  loaded: false,
  lastPageState: {
    activeTabOnPage: AvailableTabsOnPage.List,
    [AvailableTabsOnPage.List]: {
      searchValue: '',
      sortOrder: SortOrder.Asc,
    },
    [AvailableTabsOnPage.Groups]: {
      searchValue: '',
      sortOrder: SortOrder.Asc,
      openedGroupId: null,
      isOpenedFreeList: false,
    },
  },
};

export const objectsReducer = createReducer(
  initialStateObjects,
  on(setLoaded, (state, { loaded }) => ({ ...state, loaded })),
  on(setLastPageStateObjects, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),

  on(storeObjects, (state, { list }) => ({ ...state, list, loaded: true })),
  on(updateLastMessageForObjects, (state, data) => {
    return {
      ...state,
      list: state.list.map((object: any) => {
        const info = data[object.id];
        return info
          ? {
              ...object,
              lastMessage: info
                ? {
                    ...object.lastMessage,
                    ...{
                      ...info,
                      pos: info?.pos ? info.pos : object.lastMessage?.pos,
                    },
                  }
                : object.lastMessage,
            }
          : object;
      }),
    };
  }),
  on(updateConnectedStatusForObject, (state, data) => {
    return {
      ...state,

      list: state.list.map((object: any) => {
        return data.oid === object.id
          ? {
              ...object,
              connectedStatus: data ? data.connectedStatus : object.connectedStatus,
            }
          : object;
      }),
    };
  }),
  on(updateObjectById, (state, data) => {
    const { updatedObject } = data;
    const list = state.list.map((object: any) => {
      return object.id === updatedObject.id ? { ...object, ...updatedObject } : object;
    });

    return {
      ...state,
      list: list,
    };
  }),

  on(addObject, (state, data) => {
    const { createdObject } = data;
    const existingObject = state.list.find((obj: any) => obj.id === createdObject.id);

    if (!existingObject) {
      const newList = [createdObject, ...state.list];
      return {
        ...state,
        list: newList,
      };
    }

    return state;
  }),
  on(deleteObjectById, (state, data) => {
    const { deletedObjectId } = data;
    return {
      ...state,
      list: state.list.filter((object: any) => object.id !== deletedObjectId),
    };
  }),
);
