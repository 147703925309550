export const defaultColor = '#10b981';

export const defaultColorArray: string[] = [
  '#4153B9',
  '#4DB251',
  '#00C2DB',
  '#2598F3',
  '#693BBA',
  '#9F28B4',
  '#EA2568',
  '#FF9A05',
  '#FFC20A',
];

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const trackSpeedColors = (trackColor: any) => {
  if (trackColor?.type === 'speed' && trackColor?.arrayColor?.length) {
    return trackColor?.arrayColor?.map((c: any) => ({
      ...c,
      minValue: c.minValue || -Infinity,
      maxValue: c.maxValue || Infinity,
    }));
  }
  return [
    { color: '#D32F2F', maxValue: 1, minValue: -Infinity },
    { color: '#388E3C', maxValue: +Infinity, minValue: 1 },
  ];
};
