import { createReducer, on } from '@ngrx/store';
import {
  addNotification,
  deleteNotificationById,
  setLastPageStateNotifications,
  storeNotifications,
  updateIsActiveNotifications,
  updateNotificationById,
} from '../actions/notifications.actions';
import { SortOrder } from '@common/utils/search-sort';

export enum AvailableTabsOnNotificationsPage {
  Settings = 'settings',
  Triggered = 'triggered',
}

export enum AvailableNotificationsFilterValue {
  Active = 'active',
  Inactive = 'inactive',
  All = 'all',
}

export type NotificationsSettingsTabPageState = {
  searchValue: string;
  filterValue: AvailableNotificationsFilterValue;
  sortOrder: SortOrder;
};

export type NotificationsTriggeredTabPageState = {
  searchValue: string;
};

export type LastNotificationsPageStateWithTabs = {
  activeTabOnPage: AvailableTabsOnNotificationsPage;
  [AvailableTabsOnNotificationsPage.Settings]: NotificationsSettingsTabPageState;
  [AvailableTabsOnNotificationsPage.Triggered]: NotificationsTriggeredTabPageState;
};

export type NotificationsState = {
  list: any[];
  lastPageState: LastNotificationsPageStateWithTabs;
};

export const initialStateNotifications: NotificationsState = {
  list: [],
  lastPageState: {
    activeTabOnPage: AvailableTabsOnNotificationsPage.Settings,
    [AvailableTabsOnNotificationsPage.Settings]: {
      searchValue: '',
      filterValue: AvailableNotificationsFilterValue.All,
      sortOrder: SortOrder.Asc,
    },
    [AvailableTabsOnNotificationsPage.Triggered]: {
      searchValue: '',
    },
  },
};

export const notificationsReducer = createReducer(
  initialStateNotifications,
  on(storeNotifications, (state, { list }) => ({ ...state, list })),
  on(updateNotificationById, (state, data) => {
    const { updatedNotification } = data;
    const list = state.list.map((notification: any) => {
      return notification.id === updatedNotification.id
        ? { ...notification, ...updatedNotification }
        : notification;
    });

    return {
      ...state,
      list: list,
    };
  }),
  on(addNotification, (state, data) => {
    const { newNotification } = data;
    const existingObject = state.list.find((notice: any) => notice.id === newNotification.id);

    if (!existingObject) {
      return {
        ...state,
        list: [...state.list, newNotification],
      };
    }

    return state;
  }),
  on(deleteNotificationById, (state, data) => {
    const { deletedNotificationId } = data;
    return {
      ...state,
      list: state.list.filter((notice: any) => notice.id !== deletedNotificationId),
    };
  }),
  on(updateIsActiveNotifications, (state, data) => {
    return {
      ...state,
      list: state.list.map((notification: any) => {
        const notice: boolean = data.id === notification.id;
        if (notice) {
          notification.isActive = data.isActive;
        }
        return { ...notification };
      }),
    };
  }),
  on(setLastPageStateNotifications, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
);
