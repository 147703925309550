import { createAction, props } from '@ngrx/store';
import { LastPageStateWithTabs } from '@store/types/basic-last-page-state';

export const getDrivers = createAction('[Drivers] Get');
export const storeDrivers = createAction('[Drivers] Store', props<{ list: any }>());

export const setLastPageStateDrivers = createAction(
  '[stateDrivers] Set last page state',
  props<{ newPageState: Partial<LastPageStateWithTabs> }>(),
);
