import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { transformLuxonDateToTuiDate } from '@common/utils/date-form-transform';
import { DateTime, Duration } from 'luxon';
import { takeUntil } from 'rxjs';
import {
  TuiContextWithImplicit,
  TuiDestroyService,
  tuiPure,
  TuiStringHandler,
  TuiTime,
} from '@taiga-ui/cdk';
import { TuiDurationOptions, tuiHeightCollapse } from '@taiga-ui/core';
import { dateTimeWithUserTimezone } from '@common/utils/format-date';

export enum AvailableDateTimePeriod {
  Today = 'today',
  Recently = 'recently',
  Exact = 'exact',
}

export enum AvailableRecentlyDateTimePeriodTypes {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}

// export type DateTimePeriodParams = {
//   periodDateStart: string | null;
//   periodDateEnd: string | null;
//   periodValue: AvailableDateTimePeriod;
//   recentlyPeriod: number;
//   recentlyPeriodType: AvailableRecentlyDateTimePeriodTypes;
//   currentPeriod: boolean;
// };

export type DateTimePeriodParamsForm = {
  periodDateStart: FormControl<any>;
  periodDateEnd: FormControl<any>;
  periodValue: FormControl<AvailableDateTimePeriod | null>;
  recentlyPeriod: FormControl<number | null>;
  recentlyPeriodType: FormControl<AvailableRecentlyDateTimePeriodTypes | null>;
  currentPeriod: FormControl<boolean | null>;
};

export type DateTimePeriodParams = {
  [P in keyof DateTimePeriodParamsForm]: DateTimePeriodParamsForm[P]['value'];
};

export const availableDateTimePeriod: {
  value: AvailableDateTimePeriod;
  title: string;
}[] = [
  { value: AvailableDateTimePeriod.Today, title: 'Today' },
  { value: AvailableDateTimePeriod.Recently, title: 'Recently' },
  { value: AvailableDateTimePeriod.Exact, title: 'Exact interval' },
];

export const availableRecentlyDateTimePeriodTypes: {
  value: AvailableRecentlyDateTimePeriodTypes;
  title: string;
}[] = [
  { value: AvailableRecentlyDateTimePeriodTypes.Days, title: 'Days' },
  { value: AvailableRecentlyDateTimePeriodTypes.Weeks, title: 'Weeks' },
  { value: AvailableRecentlyDateTimePeriodTypes.Months, title: 'Months' },
];

@Component({
  selector: 'app-date-time-period',
  templateUrl: './date-time-period.component.html',
  styleUrls: ['./date-time-period.component.less'],
  providers: [TuiDestroyService],
  animations: [tuiHeightCollapse],
})
export class DateTimePeriodComponent implements AfterViewInit {
  @tuiPure
  getAnimation(duration: number): TuiDurationOptions {
    return { value: '', params: { duration } };
  }

  protected readonly AvailableDateTimePeriod = AvailableDateTimePeriod;
  availablePeriods = availableDateTimePeriod;
  availableRecentlyPeriodTypes = availableRecentlyDateTimePeriodTypes;

  @Input() formGroup: FormGroup<DateTimePeriodParamsForm> = new FormGroup<DateTimePeriodParamsForm>(
    {
      periodDateStart: new FormControl(null, [Validators.required]),
      periodDateEnd: new FormControl(null, [Validators.required]),
      periodValue: new FormControl(AvailableDateTimePeriod.Today),
      recentlyPeriod: new FormControl(1),
      recentlyPeriodType: new FormControl<AvailableRecentlyDateTimePeriodTypes>(
        AvailableRecentlyDateTimePeriodTypes.Days,
      ),
      currentPeriod: new FormControl(false),
    },
  );

  dateNow: DateTime = dateTimeWithUserTimezone();

  currentDatetime: any = transformLuxonDateToTuiDate(this.dateNow.endOf('day'), {
    withTime: true,
  });

  @tuiPure
  stringifyValue(
    items: {
      value: string | number;
      title: string;
    }[],
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map: any = new Map(
      items.map(
        ({ value, title }) => [value, this.translate.instant(title)] as [string | number, string],
      ),
    );

    return ({ $implicit }: TuiContextWithImplicit<string>) => map.get($implicit) || '';
  }

  constructor(
    private translate: TranslateService,
    @Inject(TuiDestroyService) private destroy$: TuiDestroyService,
  ) {}

  ngAfterViewInit() {
    const controls = this.formGroup.controls;

    if (!controls.periodDateStart.value) {
      controls.periodDateStart.setValue(
        transformLuxonDateToTuiDate(this.dateNow.startOf('day'), {}),
      );
    }

    if (!controls.periodDateEnd.value) {
      controls.periodDateEnd.setValue(transformLuxonDateToTuiDate(this.dateNow.endOf('day'), {}));
    }

    controls.periodDateStart.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (
        new Date(value[0]?.toUtcNativeDate()) >
        new Date(controls.periodDateEnd.value[0].toUtcNativeDate())
      ) {
        controls.periodDateEnd.setValue([value[0], new TuiTime(23, 59, 59, 999)]);
      }
    });

    controls.periodValue.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => this.onChangePeriodValue(value));
    controls.currentPeriod.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setRecentlyDates());
    controls.recentlyPeriodType.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setRecentlyDates());
    controls.recentlyPeriod.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setRecentlyDates());
  }

  onChangePeriodValue(value: any) {
    if (value == 'recently') {
      this.setRecentlyDates();
    } else if (value == 'today') {
      this.formGroup.patchValue({
        periodDateStart: transformLuxonDateToTuiDate(this.dateNow.startOf('day'), {}),
        periodDateEnd: transformLuxonDateToTuiDate(this.dateNow.endOf('day'), {}),
      });
    }
  }

  setRecentlyDates() {
    const { currentPeriod, recentlyPeriodType, recentlyPeriod } = this.formGroup.controls;

    const { startDate, endDate } = this.checkRecentlyPeriodType(
      recentlyPeriod.value,
      recentlyPeriodType.value,
      currentPeriod.value,
    );

    this.formGroup.patchValue({
      periodDateStart: transformLuxonDateToTuiDate(startDate, { withTime: true }),
      periodDateEnd: transformLuxonDateToTuiDate(endDate, { withTime: true }),
    });
  }

  checkRecentlyPeriodType(value: any, unit: any, withCurrentPeriod: any) {
    let startDate: any;
    let endDate: any;

    const minusDurationEnd = this.dateNow.minus(Duration.fromObject({ [unit]: 1 }));

    const minusDurationStart = (start: any) => {
      return start.minus(Duration.fromObject({ [unit]: value !== null ? value - 1 : null }));
    };

    if (unit === availableRecentlyDateTimePeriodTypes[0].value) {
      endDate = withCurrentPeriod ? this.dateNow.endOf('day') : minusDurationEnd.endOf('day');
      startDate = minusDurationStart(endDate).startOf('day');
    } else if (unit === availableRecentlyDateTimePeriodTypes[1].value) {
      endDate = withCurrentPeriod ? this.dateNow.endOf('day') : minusDurationEnd.endOf('week');
      startDate = minusDurationStart(endDate).startOf('week');
    } else if (unit === availableRecentlyDateTimePeriodTypes[2].value) {
      endDate = withCurrentPeriod ? this.dateNow.endOf('day') : minusDurationEnd.endOf('month');
      startDate = minusDurationStart(endDate).startOf('month');
    }

    return { startDate, endDate };
  }

  recentlyPeriodIncrement() {
    this.formGroup.controls.recentlyPeriod.setValue((this.formGroup.value.recentlyPeriod || 0) + 1);
  }

  recentlyPeriodDecrement() {
    let value = (this.formGroup.value.recentlyPeriod || 0) - 1;
    this.formGroup.controls.recentlyPeriod.setValue(value <= 0 ? 1 : value);
  }

  onFocusedChangePeriodDateStart($event: boolean) {
    const dateStartValue = this.formGroup.value.periodDateStart;
    if (!$event && dateStartValue[0] && !dateStartValue[1]) {
      this.formGroup.controls.periodDateStart.setValue([
        dateStartValue[0],
        new TuiTime(0, 0, 0, 0),
      ]);
    }
  }

  onFocusedChangePeriodDateEnd($event: boolean) {
    const dateEndValue = this.formGroup.value.periodDateEnd;
    if (!$event && dateEndValue[0] && !dateEndValue[1]) {
      this.formGroup.controls.periodDateEnd.setValue([
        dateEndValue[0],
        new TuiTime(23, 59, 59, 999),
      ]);
    }
  }
}
