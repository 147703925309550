import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { DriversService } from '@services/drivers.service';
import { getDrivers, storeDrivers } from '../actions/drivers.actions';
import { getSortedList, SortOrder } from '@common/utils/search-sort';

@Injectable()
export class DriversEffects {
  getDrivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDrivers),
      exhaustMap(() =>
        this.driversService.driversList().pipe(
          map((drivers: any) => {
            return storeDrivers({ list: getSortedList(drivers, SortOrder.Asc) });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private driversService: DriversService,
  ) {}
}
