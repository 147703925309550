import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { NotificationsService } from '@services/notifications.service';
import { getNotifications, storeNotifications } from '../actions/notifications.actions';
import { getSortedList, SortOrder } from '@common/utils/search-sort';

@Injectable()
export class NotificationsEffects {
  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotifications),
      exhaustMap(() =>
        this.notificationsService.list().pipe(
          map((notifications: any) => {
            return storeNotifications({ list: getSortedList(notifications, SortOrder.Asc) });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
  ) {}
}
