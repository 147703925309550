import { createAction, props } from '@ngrx/store';
import { LastTracksPageStateWithTabs } from '@store/reducers/tracks.reducer';
import { CreateTrackParams } from '@services/tracks.service';

export const setLastPageStateTracks = createAction(
  '[stateTracks] Set last page state',
  props<{
    newPageState: Partial<LastTracksPageStateWithTabs>;
  }>(),
);

export const setParamsCreateTrack = createAction(
  '[stateTracks] Set params create track',
  props<{
    params: CreateTrackParams;
    cacheKey: string;
  }>(),
);

export const deleteTracksFromTrackPage = createAction(
  '[stateTracks] Delete track from cache',
  props<{
    cacheKeys: string[];
  }>(),
);
