import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { BuildingService } from '@services/buildings.service';
import { getBuildings, storeBuildings } from '../actions/buildings.actions';
import { getSortedList, SortOrder } from '@common/utils/search-sort';

@Injectable()
export class BuildingsEffects {
  getBuildings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuildings),
      exhaustMap(() =>
        this.buildingService.list().pipe(
          map((buildings) => {
            return storeBuildings({ list: getSortedList(buildings, SortOrder.Asc) });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private buildingService: BuildingService,
  ) {}
}
