import { createReducer, on } from '@ngrx/store';
import { SortOrder } from '@common/utils/search-sort';
import { setLastPageStateDevices } from '@store/actions/devices.actions';

export type DevicesState = {
  lastPageState: LastPageStateDevices;
};

export type LastPageStateDevices = {
  searchValue: string;
  sortOrder: SortOrder;
};

export const initialStateDevices: DevicesState = {
  lastPageState: {
    searchValue: '',
    sortOrder: SortOrder.Asc,
  },
};

export const devicesReducer = createReducer(
  initialStateDevices,
  on(setLastPageStateDevices, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),
);
